/* eslint-disable no-undef,no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './stylesheets/dashboard.css';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

ReactDOM.render(

  <BrowserRouter >
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter >,
  document.getElementById('root'),
);

serviceWorker.unregister();
