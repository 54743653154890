/* eslint-disable no-undef,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/img-redundant-alt,jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { Spin, Card, Icon } from 'antd';
import request from 'request';
import querystring from 'querystring';
import Cookies from 'universal-cookie';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import AddPlaylist from './AddPlaylist';
import NavBar from './Navbar';
import '../stylesheets/dashboard.css';
import credentials from '../credentials';


const cookies = new Cookies();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaylistsLoading: true,
      playlists: [],

    };
  }

  componentDidMount() {
    const parsedQuery = querystring.parse(this.props.location.search);
    const queryRefreshToken = parsedQuery['?refresh_token'];

    if (!queryRefreshToken) {
      if (!cookie.load('refresh_token')) {
        this.login();
      } else {
        this.fetchPlaylists();
      }
    } else {
      cookies.set('refresh_token', queryRefreshToken);
      this.fetchPlaylists();
    }
  }

  fetchPlaylists() {
    const refreshToken = cookie.load('refresh_token');
    const options = {
      method: 'GET',
      url: `${credentials.backend_url}/get-dashboard`,
      qs: { refresh_token: refreshToken },
    };
    request(options, (error, response, body) => {
      if (error) throw new Error(error);
      this.setState({
        playlists: JSON.parse(body).data,
        isPlaylistsLoading: false,
      });
    });
  }
  login() {
    const stateKey = 'spotify_auth_state';
    const generateRandomString = function (length) {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < length; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    const state = generateRandomString(16);
    cookies.set(stateKey, state);
    const scope = 'user-read-private user-read-email playlist-read-private playlist-modify-private playlist-modify-public playlist-read-collaborative';
    window.open(`https://accounts.spotify.com/authorize?${
      querystring.stringify({
        response_type: 'code',
        client_id: credentials.client_id,
        scope,
        redirect_uri: credentials.redirect_uri,
        state,
      })}`, '_self');
  }


  renderPlaylists(playlists) {
    if (playlists === 'Invalid Refresh Token') {
      const renderPlaylists = (
        <div className="playlist-item">
          <center><p>We are having trouble fetching your playlists...</p></center>
          <center><p>Please <a onClick={() => this.login()} >Re-login</a></p></center>
        </div>
      );
      return (
        renderPlaylists
      );
    }
    if (playlists.length === 0) {
      const renderPlaylists = (
        <div className="playlist-item">
          <center><p>No Playlists To Show</p></center>
          <center><p>Please create a new playlist or subscribe to an existing one</p></center>
        </div>
      );
      return (
        renderPlaylists
      );
    }
    const renderPlaylists = playlists.map(playlist => (
      <div className="playlist-item" key={playlist.id}>
        <Card
          type="inner"
          style={{
                        backgroundColor: '#d4ecdc',
                        width: '300px',
                        textAlign: 'center',
                        margin: '0px',
                        borderRadius: '10px',
                    }}
        >
          <img
            alt="playlist-image"
            height={200}
            src={playlist.cover_photo}
            onClick={() => window.open(playlist.external_url, '_blank')}
          />
          <p className="playlist-name">{playlist.name}</p>
          <p className="playlist-content">{playlist.num_tracks ? `${((playlist.num_tracks_clean / playlist.num_tracks) * 100).toFixed(0)}% Clean | ${((playlist.num_tracks_explicit / playlist.num_tracks) * 100).toFixed(0)}% Explicit` : 'There are no songs in this playlist. Please refresh the page if this is incorrect'}</p>
          <AddPlaylist playlistData={playlist} isPlaylistEmpty={!playlist.num_tracks} />
        </Card>
      </div>
    ));
    return (
      renderPlaylists
    );
  }

  render() {
    document.body.style.backgroundColor = '#F0E5EB';
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    return (
      <div>
        <NavBar isHomeSelected={false} isDashboardSelected />
        <div className="playlist-container">

          {this.state.isPlaylistsLoading ? (
            <Spin tip="Loading Playlists..." indicator={antIcon} spinning />
                    ) : (
                        this.renderPlaylists(this.state.playlists)
                    )}
        </div>

      </div>
    );
  }
}

Dashboard.propTypes = {
  location: PropTypes.objectOf(),
};

export default Dashboard;
