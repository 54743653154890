/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,no-undef */

import React from 'react';
import { Card, Button } from 'antd';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import NavBar from './Navbar';
import '../stylesheets/home.css';
import clean_demo_image from '../images/clean_demo_image.png';

function Home(props) {
  document.body.style.backgroundColor = '#F0E5EB';
  return (
    <div>
      <NavBar isHomeSelected isDashboardSelected={false} />
      <div>
        <Card
          bordered
          style={{
                backgroundColor: '#d4ecdc',
                width: '100%',
                textAlign: 'center',
                margin: '0px',
            }}
        >
          <p className="pure-music-title">SanitizeMusic</p>
          <p className="pure-music-description">Clean the sh*t out of your playlists</p>
          <Button className="main-button" onClick={() => props.history.push('/dashboard')}>Lets Get
                        Started
          </Button>
        </Card>
      </div>
      <div className="about-section">

        <Card
          bordered
          style={{
            backgroundColor: '#E1EBF5',
            width: '50%',
            borderRadius: '10px',
          }}
        >
          <p className="about-section-heading">Overview</p>
          <p className="about-section-content">Tired of hearing bad words in your songs? SanitizeMusic removes
                        explicit songs from your playlists in just a few seconds!
          </p>
          <img
            alt=""
            className="demo-image"
            src={clean_demo_image}
          />
        </Card>
        <Card
          bordered
          style={{
            backgroundColor: '#FCF9DA',
            width: '50%',
            borderRadius: '10px',
          }}
        >
          <p className="about-section-heading">How it Works</p>
          <p className="about-section-content">SanitizeMusic does not modify any existing playlists. Instead, it
                        creates a new playlist with non-explicit versions of the songs from your selected playlist.
            <ol className="about-section-content">
              <li>Create an empty new-playlist.</li>
              <li>Immediately add the clean songs from your inputted playlist.</li>
              <li>Use the <a href="https://developer.spotify.com/documentation/web-api/reference/search/search/">Spotify Search Endpoint</a> to look for a non-explicit counterpart.
              </li>
              <li>Add the non-explicit song to the newly-created playlist.</li>
            </ol>
          </p>
          <p className="about-section-heading">Key Features</p>
          <ul className="about-section-content">
            <li>If SanitizeMusic cannot find a non-explicit version of the song, it will not add it to the
                            newly-created playlist.
            </li>
            <li>SanitizeMusic does not modify any existing playlists</li>
            <li>SanitizeMusic only adds a maximum of 50 songs to a playlist due to <a href="https://developer.spotify.com/documentation/web-api/"> Spotify&apos;s Rate Limiting</a>
            </li>
          </ul>
        </Card>
        <Card
          bordered
          style={{
                backgroundColor: '#FCDFD7',
                width: '50%',
                borderRadius: '10px',
          }}
        >
          <p className="about-section-heading">Documentation</p>
          <p className="about-section-content">SanitizeMusic uses React to render the front end and Node to handle
                        backend logic.
                        Design inspiration comes from the beautiful web applications presented on <a href="https://developer.spotify.com/community/showcase/">Spotify&apos;s Developer Showcase.</a>
          </p>
          <p className="about-section-heading">Resources</p>
          <ul className="about-section-content">
            <li><a href="https://ant.design/">Ant Design</a></li>
            <li><a href="https://developer.spotify.com/documentation/web-api/">Spotify API</a></li>
            <li><a href="https://react-bootstrap.github.io/">React-Bootstrap</a></li>
            <li><a href="https://www.flaticon.com/free-icon/music-player_149103">Icon</a> made by <a href="https://www.flaticon.com/authors/gregor-cresnar">Gregor Cresnar</a>
            </li>
          </ul>
        </Card>
      </div>
      <div className="credits-section">
        <p className="credits">Created by <a onClick={() => window.open('https://github.com/ChiragAswani/')}>@ChiragAswani</a>. Powered by <a onClick={() => window.open('https://www.spotify.com/us/')}>Spotify</a>.
        </p>
      </div>
    </div>

  );
}
Home.propTypes = {
  history: PropTypes.objectOf(),
};

export default Home;
