/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import '../stylesheets/navigationbar.css';

function NavBar(props) {
  return (
    <div>
      <Navbar inverse collapseOnSelect staticTop >
        <Navbar.Header>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          <Nav>
            <NavItem eventKey={1} onClick={() => props.history.push('/home')}>
              <button className="pure-music-button">SanitizeMusic</button>
            </NavItem>
          </Nav>
          <Nav pullRight>
            <NavItem eventKey={1} onClick={() => props.history.push('/home')}>
              <p className="nav-item" style={props.isHomeSelected ? { color: '#1ED760' } : { color: 'white' }}>Home</p>
            </NavItem>
            <NavItem eventKey={2} onClick={() => props.history.push('/dashboard')}>
              <p className="nav-item" style={props.isDashboardSelected ? { color: '#1ED760' } : { color: 'white' }}>Dashboard</p>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

NavBar.propTypes = {
  isHomeSelected: PropTypes.checkPropTypes(),
  isDashboardSelected: PropTypes.checkPropTypes(),
  history: PropTypes.objectOf(),
};

export default withRouter(NavBar);
