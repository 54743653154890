/* eslint-disable no-undef */
import React from 'react';
import cookie from 'react-cookies';
import request from 'request';
import { Modal, Button, Icon, Spin, Steps } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../stylesheets/dashboard.css';
import credentials from '../credentials';

const Step = Steps.Step;


class AddPlaylist extends React.Component {
  constructor(props) {
    super(props);
    this.currentStep = 0;
    this.state = {
      visible: false,
      isStepUpdating: false,
      currentStep: 0,
      isOkayDisabled: true,
    };
  }
  componentDidUpdate() {
    if (this.state.isStepUpdating) setTimeout(this.updateStep, 500);
  }
  showModal() {
    const playlistName = `${this.props.playlistData.name} [clean]`;
    const playlistDescription = `A clean version of ${playlistName} developed by PureMusic`;
    this.createPlaylist(playlistName, playlistDescription, true);
    this.setState({
      visible: true,
      isStepUpdating: true,
    });
  }
    updateStep = () => {
      if (this.currentStep >= 3) {
        this.currentStep = 0;
        this.setState({ isStepUpdating: false });
        return;
      }
      this.currentStep += 1;
      this.setState({ currentStep: this.currentStep });
    }

    createPlaylist(playlistName, playlistDescription, isPublic) {
      this.updateStep();
      const options = {
        method: 'POST',
        url: `${credentials.backend_url}/create-clean-playlist`,
        form: {
          refresh_token: cookie.load('refresh_token'),
          playlistName,
          playlistDescription,
          isPublic,
          tracks_url: this.props.playlistData.tracks_url,
        },
      };
      request(options, (error, response, body) => {
        const parsedBody = JSON.parse(body);
        const userID = parsedBody.user_id;
        const playlistID = parsedBody.playlist_id;
        this.setState({
          isOkayDisabled: false, isStepUpdating: false, playlist_id: playlistID, user_id: userID,
        });
      });
    }

    cleanInputs() {
      window.location.reload();
      this.setState({
        visible: false,
        currentStep: 0,
        isStepUpdating: false,
        isOkayDisabled: true,
      });
    }
    renderEmbeddedPlaylist(playlistID, userID) {
      const url = `https://open.spotify.com/embed/user/${userID}/playlist/${playlistID}`;
      return (
        <iframe
          title="embedded-playlist"
          src={url}
          width="100%"
          height="380"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media"
        />
      );
    }

    render() {
      const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
      return (
        <div>
          <Button className="clean-playlist-button" onClick={() => this.showModal()} disabled={this.props.isPlaylistEmpty}>Clean Playlist</Button>
          <Modal
            visible={this.state.visible}
            onOk={() => this.cleanInputs()}
            okText="Back to Dashboard"
            okButtonProps={{ disabled: this.state.isStepUpdating }}
            onCancel={() => this.cleanInputs()}
            maskClosable={false}
            closable={false}
          >
            <Steps current={this.state.currentStep} direction="vertical">
              <Step title="Creating a New Playlist" description="PureMusic does not modify any existing playlists" />
              <Step title="Cleaning Songs" description="Finding clean versions of explicit songs" />
              <Step title="Finishing" />
            </Steps>

            {this.state.isOkayDisabled ? (
              <center><Spin tip="Loading Interactive Playlist..." indicator={antIcon} spinning /></center>
                    ) : (
                        this.renderEmbeddedPlaylist(this.state.playlist_id, this.state.user_id)
                    )}
          </Modal>
        </div>
      );
    }
}

AddPlaylist.propTypes = {
  playlistData: PropTypes.objectOf(),
  isPlaylistEmpty: PropTypes.checkPropTypes(),
};


export default withRouter(AddPlaylist);
